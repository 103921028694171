import { axios } from "./config.js";
import type {
	TAuthVerifyCreate,
	TAuthVerifyResend,
	TAxiosGenRes,
	TBasicType,
	TForgotPass,
	TLoginParam,
	TLoginRes,
	TResetPass,
} from "./types";

export default {
	// Auth
	authLogin: (params: TLoginParam) =>
		axios.post<TLoginRes>("auth/login", params),
	authRegister: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/register", params),
	authRenewSession: () =>
		axios.post<{ data: { data: string } }>("auth/renew-jwt"),
	authLogout: () => axios.get("auth/logout", { validateStatus: null }),
	authVerifyCreate: (params: TAuthVerifyCreate) =>
		axios.post<TLoginRes>("auth/verify", params),
	authVerifyResend: (params: TAuthVerifyResend) =>
		axios.put("auth/verify", params),
	authForgot: (params: TForgotPass) =>
		axios.post("auth/forgot-password", params),
	authResetPass: (params: TResetPass) =>
		axios.put("auth/forgot-password", params),
	// Leads
	leadsGet: (params: any) =>
		axios.get<TAxiosGenRes<TBasicType>>("leads/leads-get", { params }),
	leadsEdit: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("leads/leads-edit", params),
	// users
	changePassword: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("users/users-edit", params),
	// chat
	chatusersGet: () =>
		axios.get<TAxiosGenRes<TBasicType>>("chatmessages/chatusers-get"),
	chatMessagesGet: (params: any) =>
		axios.get<TAxiosGenRes<TBasicType>>("chatmessages/chatmessages-get", {
			params,
		}),
	chatMessagesAdd: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>(
			"chatmessages/chatmessages-add",
			params,
		),
	chatMessagesSetSeen: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>(
			"chatmessages/cms_usr_unseen-remove",
			params,
		),
	chatMessagesUnseenGet: () =>
		axios.get<TAxiosGenRes<TBasicType>>("chatmessages/cms_usr_unseen-get"),
	chatusersEdit: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("chatmessages/chatusers-edit", params),
	adminSettingsEdit: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>(
			"adminsettings/adminsettings-edit",
			params,
		),
	adminSettingsGet: (params: any) =>
		axios.get<TAxiosGenRes<TBasicType>>("adminsettings/adminsettings-get", {
			params,
		}),
};
