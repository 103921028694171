<script setup lang="ts">
import api from "@/api";
import {
	getSessionStorageReac,
	setSessionStorageReac,
} from "@/assets/js/helpers";
import { useWebSocketStore } from "@/store/websocket";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

definePage({
	name: "ChatPage",
});

export interface IChatUser {
	cus_id: number;
	cus_token: string;
	cus_utc_last_activity: string;
	cus_label: string | null;
	cus_referrer: string | null;
	cus_city: string | null;
	cus_country_code: string | null;
	message_count: number;
}

const wsStore = useWebSocketStore();
const selectedUserToken = ref<string | null>(null);
const selectedUserReferrer = ref<string | null>(null);
const selectedUserCity = ref<string | null>(null);
const selectedUserCountryCode = ref<string | null>(null);
const selectedUserLabel = ref<string | null>(null);
const messageInput = ref("");
const isVisibleChatSidebar = ref(false);
const profileModalBool = ref(false);
const chatBottomRightTop = ref<HTMLElement | null>(null);
const chatBoxIsBottom = ref(true);
let scrollTimeout: ReturnType<typeof setTimeout> | null = null;
const chatBoxToBottom = ref<HTMLElement | null>(null);
const chatBottomRightBottom = ref<HTMLElement | null>(null);

async function getChatusers() {
	try {
		const res = await api.chatusersGet();
		wsStore.chatusers = res.data.data;
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

async function getChatMessages(cus_id: number) {
	try {
		const res = await api.chatMessagesGet({ cus_id });
		wsStore.chatMessages = res.data.data;
		// console.log(res.data.data);
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

function openChat(user: IChatUser) {
	setEventListener();
	setSessionStorageReac("chat-user", user);
	wsStore.selectedUserId = user.cus_id;
	selectedUserToken.value = user.cus_token;
	selectedUserReferrer.value = user.cus_referrer;
	selectedUserCity.value = user.cus_city;
	selectedUserCountryCode.value = user.cus_country_code;
	selectedUserLabel.value = user.cus_label;
	getChatMessages(wsStore.selectedUserId);
	setSeen(user.cus_id);
	isVisibleChatSidebar.value = false;
	// adjustscrollHeight(300, "instant");
	setTimeout(() => {
		toChatBottom("instant");
	}, 300);
	setChatBoxToBottomPosition();
}

async function setSeen(cus_id: number) {
	const params = {
		cus_id: cus_id,
	};

	try {
		const res = await api.chatMessagesSetSeen(params);
		console.warn(res.data.message);

		if (wsStore.notificationsByChat) {
			const userNotif = wsStore.notificationsByChat.find(
				(notif) => notif.cus_id === cus_id,
			);

			if (userNotif) {
				wsStore.totalNotifications -= userNotif.total_cms;
				userNotif.total_cms = 0;
			}
		}
	} catch (e: any) {
		console.error(e);
	}
}

async function sendMessage() {
	if (!messageInput.value || !messageInput.value.trim()) {
		messageInput.value = "";
		return;
	}

	const params = {
		cus_id: wsStore.selectedUserId,
		message: messageInput.value,
		from: "admin",
	};

	try {
		const res = await api.chatMessagesAdd(params);
		console.warn(res.data.message);
		// console.log(wsStore.wsInstance);
		if (wsStore.wsInstance) {
			// console.log("123");
			wsStore.wsInstance.send({
				scope: "live_chat",
				type: "message",
				data: {
					cms_message: messageInput.value,
					cms_date: dayjs().format("MMM, DD. YYYY. HH:mm:ss"),
					cms_from: "admin",
					cus_id: wsStore.selectedUserId,
					cus_token: selectedUserToken.value,
				},
			});
		}

		wsStore.addMessage(
			messageInput.value,
			dayjs().format("MMM, DD. YYYY. HH:mm:ss"),
			"admin",
			wsStore.selectedUserId,
		);

		// Clear input field after sending
		messageInput.value = "";

		setTimeout(() => {
			toChatBottom("smooth");
		}, 200);
		adjustTextareaHeight(
			{
				target: textareaElement.value,
			} as unknown as Event,
			true,
		);
	} catch (e: any) {
		console.error(e);
	}
}

const textareaElement = ref<HTMLElement | null>(null);

function adjustTextareaHeight(event: Event, handleSubmit: boolean = false) {
	const textarea = event.target as HTMLTextAreaElement;
	textarea.style.height = "22px";
	if (!handleSubmit) textarea.style.height = `${textarea.scrollHeight}px`;
	setChatBoxToBottomPosition();
}

function adjustscrollHeight(time: number, behavior: ScrollBehavior) {
	if (chatBoxIsBottom.value) {
		setTimeout(() => {
			toChatBottom(behavior);
		}, time);
	}
}

function toChatBottom(behavior: ScrollBehavior) {
	// Scroll to the bottom of the chat box
	if (chatBottomRightTop.value) {
		chatBottomRightTop.value.scrollTo({
			top: chatBottomRightTop.value.scrollHeight,
			behavior: behavior,
		});
	}
}

function setChatBoxToBottomPosition() {
	if (chatBoxToBottom.value && chatBottomRightBottom.value) {
		chatBoxToBottom.value.style.bottom =
			chatBottomRightBottom.value.offsetHeight + 10 + "px";
	}
}

function handleChatBoxScroll(event: Event) {
	const el = event.target as HTMLElement;
	const scrollTop = el.scrollTop;
	const scrollHeight = el.scrollHeight;
	const clientHeight = el.clientHeight;

	if (scrollTop + clientHeight >= scrollHeight - 1) {
		chatBoxIsBottom.value = true;
		if (scrollTimeout) {
			clearTimeout(scrollTimeout);
		}
	} else {
		if (scrollTimeout) {
			clearTimeout(scrollTimeout);
		}
		scrollTimeout = setTimeout(() => {
			chatBoxIsBottom.value = false;
		}, 300);
	}
}

function setEventListener() {
	setTimeout(() => {
		if (chatBottomRightTop.value) {
			chatBottomRightTop.value.removeEventListener(
				"scroll",
				handleChatBoxScroll,
			);

			chatBottomRightTop.value.addEventListener("scroll", handleChatBoxScroll);
		}
	}, 300);
}

setTimeout(() => {
	const chatBoxWrapper = document.querySelector(
		".chat-box-center-wrapper",
	) as HTMLElement;

	if (chatBoxWrapper) {
		chatBoxWrapper.removeEventListener("scroll", handleChatBoxScroll);

		chatBoxWrapper.addEventListener("scroll", handleChatBoxScroll);
	}
}, 300);

const profileModalConfig = {
	title: "User Details",
	isOverlayTransparent: true,
	// handleDraggable: false,
};

const profileModalStyle = {};

function handleKeyDown(event: KeyboardEvent) {
	if (event.key === "Enter" && !event.shiftKey) {
		event.preventDefault();
		sendMessage();
	} else if (event.key === "Enter" && event.shiftKey) {
		return;
	}
}

watch(
	() => wsStore.newMsgCounter,
	(val) => {
		adjustscrollHeight(200, "smooth");
	},
);

watch(messageInput, (newValue, oldValue) => {
	if (newValue.length > 0 && oldValue.length === 0) {
		if (wsStore.wsInstance) {
			wsStore.wsInstance.send(
				JSON.stringify({
					scope: "live_chat",
					type: "typing",
					data: {
						cms_from: "admin",
						cus_token: selectedUserToken.value,
					},
				}),
			);
		}
	} else if (
		newValue.length === 0 &&
		oldValue.length > 0 &&
		wsStore.wsInstance
	) {
		wsStore.wsInstance.send(
			JSON.stringify({
				scope: "live_chat",
				type: "not_typing",
				data: {
					cms_from: "admin",
					cus_token: selectedUserToken.value,
				},
			}),
		);
	}
});

onMounted(async () => {
	await getChatusers();
	// console.log(chatusers.value);
	const user = (getSessionStorageReac("chat-user").value as string) || null;
	if (user) openChat(JSON.parse(user));
});
</script>

<template>
	<div>
		<header-bar></header-bar>
		<div class="chat-wrapper">
			<div class="chat-top">
				<i-fa-angles-right
					class="chat-top-icon"
					@click="isVisibleChatSidebar = true"
				/>
			</div>
			<div class="chat-bottom">
				<chat-sidebar
					:chatusers="wsStore.chatusers"
					:isVisibleChatSidebar="isVisibleChatSidebar"
					@open-chat="openChat($event)"
					@handle-click-outside="isVisibleChatSidebar = false"
				/>
				<div class="chat-bottom-right">
					<div
						v-if="wsStore.selectedUserId && selectedUserToken"
						class="chat-bottom-right-container"
					>
						<div
							ref="chatBoxToBottom"
							class="chat-bottom-right-top-scroll-to-bottom"
							:class="{
								'chat-bottom-right-top-scroll-to-bottom-visible':
									!chatBoxIsBottom,
							}"
						>
							<img
								src="@/assets/icons/scrollTopChat.svg"
								alt="scroll bottom"
								@click="toChatBottom('smooth')"
							/>
						</div>
						<div
							v-if="wsStore.chatMessages"
							ref="chatBottomRightTop"
							class="chat-bottom-right-top"
						>
							<div
								class="chat-bottom-right-top-profile-wrapper"
								@click="profileModalBool = true"
							>
								<i-fa-user />
								<modern-modal
									v-if="profileModalBool"
									:config="profileModalConfig"
									:form-style="profileModalStyle"
									@close-modal="profileModalBool = false"
								>
									<template #content>
										<div class="profile-modal-lwrapper">
											<div class="profile-modal-label">
												{{
													selectedUserLabel ?? `User ${wsStore.selectedUserId}`
												}}
											</div>
											<div class="profile-modal-item">
												{{ selectedUserCity }}, {{ selectedUserCountryCode }}
											</div>
											<div class="profile-modal-item">
												{{ selectedUserReferrer }}
											</div>
										</div>
									</template>
								</modern-modal>
							</div>
							<div
								class="chat-bottom-right-top-message chat-bottom-right-top-message-admin"
							>
								Thanks for visiting us!
							</div>
							<div
								class="chat-bottom-right-top-message chat-bottom-right-top-message-admin"
							>
								What can I help you with today? In case we get disconnected,
								what's your email?
							</div>
							<div
								v-for="msg in wsStore.chatMessages"
								:key="msg.cms_id"
								class="chat-bottom-right-top-message"
								:class="{
									'chat-bottom-right-top-message-user': msg.cms_from === 'user',
									'chat-bottom-right-top-message-admin':
										msg.cms_from === 'admin',
								}"
								:title="dayjs(msg.cms_date).format('MMM, DD. YYYY. HH:mm:ss')"
							>
								<div class="chat-bottom-right-top-message-date">
									{{ dayjs(msg.cms_date).format("HH:mm") }}
								</div>
								{{ msg.cms_message }}
							</div>
							<div
								v-if="wsStore.typingBool"
								class="chat-bottom-right-top-message chat-bottom-right-top-message-user"
							>
								Client is typing...
							</div>
						</div>
						<p
							v-else
							class="chat-bottom-right-top"
						>
							No mesages.
						</p>
						<div
							ref="chatBottomRightBottom"
							class="chat-bottom-right-bottom"
						>
							<div class="chat-bottom-right-bottom-left">
								<textarea
									v-if="wsStore.chatMessages"
									ref="textareaElement"
									v-model="messageInput"
									rows="1"
									placeholder="Write a message"
									class="chat-bottom-right-bottom-left-input"
									@keydown="handleKeyDown"
									@input="adjustTextareaHeight"
								/>
							</div>
							<div class="chat-bottom-right-bottom-right">
								<img
									src="@/assets/icons/send-icon.svg"
									alt="send"
									@click="sendMessage()"
								/>
							</div>
						</div>
					</div>
					<div
						v-else
						class="chat-bottom-right-container chat-bottom-right-container-start"
					>
						Select a chat to start messaging
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.chat-wrapper {
	overflow: hidden;
	.chat-top {
		height: 40px;

		.chat-top-icon {
			height: 90%;
			font-size: 24px;
			cursor: pointer;
			margin-left: 40px;

			&:hover {
				opacity: 0.8;
			}
		}
	}
	.chat-bottom {
		height: calc(100vh - $nav-top-bar - 41px);
		display: flex;

		.chat-bottom-right {
			height: 100%;
			width: 100%;

			.chat-bottom-right-container {
				display: flex;
				flex-direction: column;
				max-height: 100%;
				height: 100%;
				overflow-x: hidden;
				position: relative;

				&.chat-bottom-right-container-start {
					justify-content: center;
					align-items: center;
					background-color: rgba($border-color1, 0.8);
				}

				.chat-bottom-right-top-scroll-to-bottom {
					position: absolute;
					right: -60px;
					bottom: 60px;
					transform: scaleY(-1);
					transition: all 0.3s ease;
					opacity: 0.4;
					cursor: pointer;
					z-index: 10;

					&.chat-bottom-right-top-scroll-to-bottom-visible {
						right: 28px;
					}

					&:hover {
						opacity: 1;
					}
				}

				.chat-bottom-right-top {
					flex: 1;
					background-color: rgba($border-color1, 0.8);
					overflow-y: auto;
					display: flex;
					flex-direction: column;
					padding: 12px;
					box-sizing: border-box;
					position: relative;
					// overflow-x: hidden;

					.chat-bottom-right-top-profile-wrapper {
						position: fixed;
						top: calc(52px + $nav-top-bar);
						right: 28px;
						height: 50px;
						width: 50px;
						border-radius: 50%;
						background-color: rgba($border-color1, 0.6);
						border: 3px solid $app-accent-color1;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 24px;
						cursor: pointer;
						color: $app-accent-color1;
						z-index: 3;

						&:hover {
							opacity: 0.8;
						}
					}

					.chat-bottom-right-top-message {
						min-width: 100px;
						position: relative;

						.chat-bottom-right-top-message-date {
							position: absolute;
							bottom: 6px;
							right: 12px;
							color: rgba($text-color, 0.6);
							font-size: 12px;
						}

						&.chat-bottom-right-top-message-user {
							border: 1px solid transparent;
							border-radius: 8px 8px 0;
							margin-bottom: 12px;
							max-width: 90%;
							align-self: flex-end;
							padding: 12px 12px 24px;
							word-wrap: break-word;
							background-color: $app-accent-color1;
						}

						&.chat-bottom-right-top-message-admin {
							border: 1px solid transparent;
							border-radius: 0 8px 8px;
							margin-bottom: 12px;
							max-width: 90%;
							align-self: flex-start;
							padding: 12px 12px 24px;
							word-wrap: break-word;
							background-color: $app-accent-color2;
						}
					}
				}

				.chat-bottom-right-bottom {
					min-height: 50px;
					display: flex;
					box-sizing: border-box;

					.chat-bottom-right-bottom-left {
						// width: 90%;
						flex: 1;
						box-sizing: border-box;
						padding: 12px 0 12px 12px;
						display: flex;
						align-items: center;

						.chat-bottom-right-bottom-left-input {
							width: 100%;
							background-color: transparent;
							font-size: 16px;
							height: 22px;
							line-height: 18px;
							resize: none;
							max-height: 66px;
							border: 1px solid transparent;
							border-radius: 0;

							&:focus {
								outline: none;
								border: none;
							}

							&::placeholder {
								font-size: 16px;
								line-height: 18px;
								font-family: Archivo, sans-serif;
							}

							&::-webkit-scrollbar {
								width: 2px;
							}

							&::-webkit-scrollbar-track {
								background-color: rgba($text-color, 0.2);
							}

							&::-webkit-scrollbar-thumb {
								background-color: rgba($medium-gray, 0.8);
								border-radius: 4px;
							}

							&::-webkit-scrollbar-thumb:hover {
								background-color: rgba($medium-gray, 0.4);
							}
						}
					}

					.chat-bottom-right-bottom-right {
						width: 10%;
						max-width: 70px;
						box-sizing: border-box;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							cursor: pointer;
							transition: opacity 0.3s ease;
							filter: brightness(0) saturate(100%) invert(56%) sepia(47%)
								saturate(413%) hue-rotate(85deg) brightness(95%) contrast(91%);
						}

						&:hover {
							img {
								opacity: 0.6;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (width >= 768px) {
	.chat-wrapper {
		.chat-top {
			display: none;
		}

		.chat-bottom {
			height: calc(100vh - $nav-top-bar - 1px);
			.chat-bottom-right {
				width: 75%;
				min-width: calc(100% - 300px);

				.chat-bottom-right-container {
					.chat-bottom-right-top {
						.chat-bottom-right-top-profile-wrapper {
							top: calc(12px + $nav-top-bar);
						}
						.chat-bottom-right-top-message {
							&.chat-bottom-right-top-message-user {
								max-width: 60%;
								align-self: flex-start;
							}

							&.chat-bottom-right-top-message-admin {
								max-width: 60%;
								align-self: flex-start;
							}
						}
					}
				}
			}
		}
	}
}

.profile-modal-lwrapper {
	padding: 12px 24px 24px;

	.profile-modal-label {
		font-weight: 700;
		font-size: 18px;
	}
}
</style>
