<script setup lang="ts">
import api from "@/api";
import type { ILead } from "@/pages/index/index.vue";
import { useDefaultStore } from "@/store";

definePage({
	name: "LeadPage",
});

const route = useRoute();
const lead = ref<ILead | null>(null);
const store = useDefaultStore();

async function getLead() {
	const params = {
		lds_id: (route.params as { id: string }).id,
	};

	lead.value = await store.getLeads(params);
}

async function editLeads(leadId: number, priority?: string, progress?: string) {
	const params = {
		lds_id: leadId,
		priority: priority ?? undefined,
		progress: progress ?? undefined,
	};
	try {
		await api.leadsEdit(params);
		// const updatedLead = leads.value.find((lead) => lead.lds_id === leadId);
		// const currentDate = DateInstance.getFormattedDate(
		// 	"",
		// 	"DD.MM.YYYY HH:mm:ss",
		// 	false,
		// );
		// updatedLead.lds_utc_updated = currentDate;
		getLead();
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

onMounted(async () => {
	await getLead();
});
</script>

<template>
	<card-comp
		v-if="lead && lead[0]"
		:lead="lead[0]"
		@edit-lead="editLeads"
	/>
</template>
