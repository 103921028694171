import { useSessionStore } from "@/store/session";
import { createRouter, createWebHistory } from "vue-router/auto";

const router = createRouter({
	history: createWebHistory(),
});

// router.beforeEach((to, from) => {
// 	// Forbid going to auth pages if user is logged in
// 	const authPage = "AuthPage";
// 	if (to.matched.some((match) => match.name === authPage)) {
// 		const isLoggedIn = useSessionStore().isLoggedIn;
// 		if (isLoggedIn) {
// 			return false;
// 		}
// 	}
// 	return true;
// });

router.beforeEach((to, from, next) => {
	const authPage = "AuthPage";
	const isLoggedIn = useSessionStore().isLoggedIn;

	if (to.matched.some((record) => record.name === authPage)) {
		if (isLoggedIn) {
			next({ name: "HomePage" });
		} else {
			next();
		}
	} else {
		if (isLoggedIn) {
			next();
		} else {
			next({ name: authPage });
		}
	}
});

export default router;
