<script setup lang="ts">
import api from "@/api";
// import { useCaptchaComposable } from "@/components/shared/recaptcha/recaptcha";
import { useToastStore } from "@/store/toast";
import { useRoute } from "vue-router";
import type { ComponentPublicInstance } from "vue";
import { CValidate } from "@/assets/js/validations";

definePage({
	name: "ResetPass",
});

const emit = defineEmits(["click-back"]);
// const { recaptcha } = useCaptchaComposable();
const toastStore = useToastStore();
const route = useRoute();

const form = reactive({ password: "" });
const resetPassInputRef = ref<ComponentPublicInstance | null>(null);
const passwordRules = CValidate.passwordRAllCombo(8);

async function clickResetPass() {
	// const google_token = await recaptcha("resetPass");
	const google_token = "";
	const params = {
		token: route.query.token as string,
		password: form.password,
		google_token,
	};
	try {
		const res = await api.authResetPass(params);
		console.warn(res);
		toastStore.openToastSuccess(
			"Password reset success. You can now proceed to login",
		);
	} catch (err: any) {
		toastStore.openToastError("Failed to reset password");
		console.error(err.message);
	}
}

onMounted(() => {
	setTimeout(() => {
		resetPassInputRef.value?.$el.querySelector("input")?.focus();
	}, 400);
});
</script>

<template>
	<div class="reset-pass">
		<modern-form>
			<template #content>
				<label><span>Enter your new password below.</span></label>
				<div class="form">
					<form-comp class="row">
						<div class="group">
							<field-comp
								ref="resetPassInputRef"
								v-model="form.password"
								type="text"
								name="password"
								:rules="passwordRules"
								autocomplete="one-time-code"
								placeholder="New Password"
								@keyup.enter="clickResetPass"
							></field-comp>
							<error-message
								class="error-msg"
								name="password"
							></error-message>
						</div>
					</form-comp>
					<button @click="clickResetPass"><span>Save password</span></button>
				</div>
			</template>
		</modern-form>
	</div>
</template>

<style lang="scss" scoped>
.reset-pass {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	.form {
		display: flex;
		flex-direction: column;
		gap: 30px;
		max-width: 350px;
		width: 350px;
		padding: 0 20px 20px;
	}
}
</style>
