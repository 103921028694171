// import cloneDeep from "lodash.clonedeep";
import { type IGlobalModal } from "@/components/shared/modal/ModernModal.vue";
import { defineStore } from "pinia";

// TODO 1 Remove this file and use pure comp usage, with index.vue init

export const useModalStore = defineStore("modal", () => {
	const globalModal = ref<IGlobalModal>({
		comp: null,
		config: {} as IGlobalModal["config"],
		formStyle: {},
	});

	const isGlobalModalVisible = computed({
		get() {
			return getModalData("comp");
		},
		set(val) {
			updateModalData(val || null, "comp");
		},
	});

	function getModalData(
		key?: keyof typeof globalModal.value,
		defaultValue?: any,
	) {
		return key ? globalModal.value[key] || defaultValue : globalModal.value;
	}

	function updateModalData(data: any, key?: keyof typeof globalModal.value) {
		if (key) {
			globalModal.value[key] = data;
		} else {
			globalModal.value = {
				...data,
				comp: markRaw(data.comp),
			};
		}
	}

	return {
		globalModal, // Used for debugging
		isGlobalModalVisible,
		getModalData,
		updateModalData,
	};
});
