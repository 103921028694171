<script setup lang="ts">
const ELEMENT_MASK = {
	"resizable-r": { bit: 0b0001, cursor: "e-resize" },
	"resizable-rb": { bit: 0b0011, cursor: "se-resize" },
	"resizable-b": { bit: 0b0010, cursor: "s-resize" },
	"resizable-lb": { bit: 0b0110, cursor: "sw-resize" },
	"resizable-l": { bit: 0b0100, cursor: "w-resize" },
	"resizable-lt": { bit: 0b1100, cursor: "nw-resize" },
	"resizable-t": { bit: 0b1000, cursor: "n-resize" },
	"resizable-rt": { bit: 0b1001, cursor: "ne-resize" },
	"drag-el": { bit: 0b1111, cursor: "pointer" },
};

const CALC_MASK = {
	l: 0b0001,
	t: 0b0010,
	w: 0b0100,
	h: 0b1000,
};

type TResizableTypes =
	| "mount"
	| "destroy"
	| "resize:start"
	| "resize:move"
	| "resize:end"
	| "drag:start"
	| "drag:move"
	| "drag:end"
	| "maximize";

const props = withDefaults(
	defineProps<{
		width?: number | string;
		minWidth?: number;
		maxWidth?: number;
		height?: number | string;
		minHeight?: number;
		maxHeight?: number;
		left?: number;
		top?: number;
		active?: ("r" | "rb" | "b" | "lb" | "l" | "lt" | "t" | "rt")[];
		// 	validator: (val: any[]) =>
		// 		["r", "rb", "b", "lb", "l", "lt", "t", "rt"].filter((value) =>
		// 			val.includes(value),
		// 		).length === val.length,
		// },
		dragSelector?: string;
		maximize?: boolean;
		disableAttributes?: ("l" | "t" | "w" | "h")[];
		// validator: (val: any[]) =>
		// 	["l", "t", "w", "h"].filter((value) => val.includes(value)).length ===
		// 	val.length,
	}>(),
	{
		width: undefined,
		minWidth: 1,
		maxWidth: undefined,
		height: undefined,
		minHeight: 1,
		maxHeight: undefined,
		left: 0,
		top: 0,
		active: () => ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
		dragSelector: "",
		maximize: false,
		disableAttributes: () => [],
	},
);
const emit = defineEmits([
	"mount",
	"destroy",
	"resize:start",
	"resize:move",
	"resize:end",
	"drag:start",
	"drag:move",
	"drag:end",
	"maximize",
]);

const data = reactive({
	ogW: typeof props.width === "number" ? props.width : 0,
	ogH: typeof props.height === "number" ? props.height : 0,
	minW: props.minWidth,
	minH: props.minHeight,
	maxW: props.maxWidth,
	maxH: props.maxHeight,
	ogLeft: props.left,
	ogTop: props.top,
	mouseX: 0,
	mouseY: 0,
	offsetX: 0,
	offsetY: 0,
	parent: { width: 0, height: 0 },
	resizeState: 0,
	dragElements: [] as any[],
	dragState: false,
	calcMap: 0b1111,
});
const resizableParentRef = ref<HTMLElement | null>(null);
const prevState = reactive<any>({});

const style = computed(() => {
	return {
		...(data.calcMap & CALC_MASK.w && {
			width: typeof data.ogW === "number" ? data.ogW + "px" : data.ogW,
		}),
		...(data.calcMap & CALC_MASK.h && {
			height: typeof data.ogH === "number" ? data.ogH + "px" : data.ogH,
		}),
		...(data.calcMap & CALC_MASK.l && {
			left: typeof data.ogLeft === "number" ? data.ogLeft + "px" : data.ogLeft,
		}),
		...(data.calcMap & CALC_MASK.t && {
			top: typeof data.ogTop === "number" ? data.ogTop + "px" : data.ogTop,
		}),
	};
});

watch(
	() => props.maxWidth,
	(value) => {
		data.maxW = value;
	},
);
watch(
	() => props.maxHeight,
	(value) => {
		data.maxH = value;
	},
);
watch(
	() => props.minWidth,
	(value) => {
		data.minW = value;
	},
);
watch(
	() => props.minHeight,
	(value) => {
		data.minH = value;
	},
);

watch(
	() => props.width,
	(value) => {
		typeof value === "number" && (data.ogW = value);
	},
);
watch(
	() => props.height,
	(value) => {
		typeof value === "number" && (data.ogH = value);
	},
);
watch(
	() => props.left,
	(value) => {
		typeof value === "number" && (data.ogLeft = value);
	},
);
watch(
	() => props.top,
	(value) => {
		typeof value === "number" && (data.ogTop = value);
	},
);
watch(
	() => props.dragSelector,
	(selector) => {
		setupDragElements(selector);
	},
);
watch(
	() => props.maximize,
	(value) => {
		setMaximize(value);
		emitEvent("maximize", { state: value });
	},
);

onMounted(() => {
	const rootEl = resizableParentRef.value;
	if (!rootEl) {
		console.error("No root el mounted");
		return;
	}

	if (!props.width) {
		data.ogW = rootEl.parentElement?.clientWidth || 0;
	} else if (props.width !== "auto") {
		typeof props.width !== "number" && (data.ogW = rootEl.clientWidth || 0);
	}
	if (!props.height) {
		data.ogH = rootEl.parentElement?.clientHeight || 0;
	} else if (props.height !== "auto") {
		typeof props.height !== "number" && (data.ogH = rootEl.clientHeight || 0);
	}
	typeof props.left !== "number" &&
		(data.ogLeft =
			rootEl.offsetLeft || 0 - (rootEl.parentElement?.offsetLeft || 0));
	typeof props.top !== "number" &&
		(data.ogTop =
			rootEl.offsetTop || 0 - (rootEl.parentElement?.offsetTop || 0));
	data.minW && data.ogW < data.minW && (data.ogW = data.minW);
	data.minH && data.ogH < data.minH && (data.ogH = data.minH);
	data.maxW && data.ogW > data.maxW && (data.ogW = data.maxW);
	data.maxH && data.ogH > data.maxH && (data.ogH = data.maxH);

	setMaximize(props.maximize);
	setupDragElements(props.dragSelector);

	for (const attr of props.disableAttributes) {
		switch (attr) {
			case "l": {
				data.calcMap &= ~CALC_MASK.l;
				break;
			}
			case "t": {
				data.calcMap &= ~CALC_MASK.t;
				break;
			}
			case "w": {
				data.calcMap &= ~CALC_MASK.w;
				break;
			}
			case "h": {
				data.calcMap &= ~CALC_MASK.h;
			}
		}
	}

	document.documentElement.addEventListener("mousemove", handleMove, true);
	document.documentElement.addEventListener("mouseup", handleUp, true);

	// document.documentElement.addEventListener("touchmove", handleMove, true);
	// document.documentElement.addEventListener("touchstart", handleDown, true);
	// document.documentElement.addEventListener("touchend", handleUp, true);
	emitEvent("mount");
});

onUnmounted(() => {
	document.documentElement.removeEventListener("mousemove", handleMove, true);
	document.documentElement.removeEventListener("mouseup", handleUp, true);

	// document.documentElement.removeEventListener(
	// 	"touchmove",
	// 	data.handleMove,
	// 	true,
	// );
	// document.documentElement.removeEventListener(
	// 	"touchstart",
	// 	data.handleDown,
	// 	true,
	// );
	// document.documentElement.removeEventListener("touchend", data.handleUp, true);
	emitEvent("destroy");
});

function setMaximize(value: boolean) {
	const parentEl = resizableParentRef.value?.parentElement;
	if (value && parentEl) {
		prevState.value = {
			w: data.ogW,
			h: data.ogH,
			l: data.ogLeft,
			t: data.ogTop,
		};
		data.ogTop = data.ogLeft = 0;
		data.ogW = parentEl.clientWidth;
		data.ogH = parentEl.clientHeight;
	} else {
		restoreSize();
	}
}
function restoreSize() {
	if (prevState) {
		data.ogLeft = prevState.l;
		data.ogTop = prevState.t;
		data.ogH = prevState.h;
		data.ogW = prevState.w;
	}
}

function setupDragElements(selector: string) {
	const rootEl = resizableParentRef.value;
	if (!rootEl) {
		console.error("No root element");
		return;
	}
	if (!selector) {
		return;
	}

	const oldList = Array.from(
		rootEl.querySelectorAll(".drag-el"),
	) as HTMLElement[];
	for (const el of oldList) {
		el.classList.remove("drag-el");
	}

	const nodeList = Array.from(
		rootEl.querySelectorAll(selector),
	) as HTMLElement[];
	for (const el of nodeList) {
		el.classList.add("drag-el");
	}
	data.dragElements = Array.prototype.slice.call(nodeList);
}
function emitEvent(
	eventName: TResizableTypes,
	additionalOptions?: Record<string, any>,
) {
	emit(eventName, {
		eventName,
		left: data.ogLeft,
		top: data.ogTop,
		width: data.ogW,
		height: data.ogH,
		...additionalOptions,
	});
}
function handleMove(event: MouseEvent) {
	if (data.resizeState !== 0) {
		if (!data.dragState) {
			if (isNaN(data.ogW)) {
				data.ogW = resizableParentRef.value?.clientWidth || 0;
			}
			if (isNaN(data.ogH)) {
				data.ogH = resizableParentRef.value?.clientHeight || 0;
			}
		}
		// if (event.touches && event.touches.length >= 0) {
		// 	eventY = event.touches[0].clientY;
		// 	eventX = event.touches[0].clientX;
		// } else {
		const eventY = event.clientY;
		const eventX = event.clientX;
		// }
		if (props.maximize && prevState) {
			const parentWidth = data.parent.width;
			const parentHeight = data.parent.height;
			restoreSize();
			prevState.value = {};
			data.ogTop = eventY > parentHeight / 2 ? parentHeight - data.ogH : 0;
			data.ogLeft = eventX > parentWidth / 2 ? parentWidth - data.ogW : 0;
			emitEvent("maximize", { state: false });
		}
		let diffX = eventX - data.mouseX + data.offsetX;
		let diffY = eventY - data.mouseY + data.offsetY;

		if (resizableParentRef.value?.getBoundingClientRect) {
			const rect = resizableParentRef.value.getBoundingClientRect();
			const scaleX =
				rect.width / resizableParentRef.value.offsetWidth || 0 || 1;
			const scaleY =
				rect.height / resizableParentRef.value.offsetHeight || 0 || 1;
			diffX /= scaleX;
			diffY /= scaleY;
		}
		data.offsetX = data.offsetY = 0;

		resizeLogic.left(diffX);
		resizeLogic.top(diffY);
		resizeLogic.right(diffX);
		resizeLogic.bottom(diffY);

		data.mouseX = eventX;
		data.mouseY = eventY;
		const eventName = data.dragState ? "drag:move" : "resize:move";
		emitEvent(eventName);
	}
}

const resizeLogic = {
	left: (diffX: number) => {
		if (data.resizeState & ELEMENT_MASK["resizable-l"].bit) {
			if (!data.dragState && data.ogW - diffX < data.minW) {
				data.offsetX = diffX - (diffX = data.ogW - data.minW);
			} else if (
				!data.dragState &&
				data.maxW &&
				data.ogW - diffX > data.maxW &&
				data.ogLeft >= 0
			) {
				data.offsetX = diffX - (diffX = data.ogW - data.maxW);
			}

			data.calcMap & CALC_MASK.l && (data.ogLeft += diffX);
			data.calcMap & CALC_MASK.w && (data.ogW -= data.dragState ? 0 : diffX);
		}
	},
	top: (diffY: number) => {
		if (data.resizeState & ELEMENT_MASK["resizable-t"].bit) {
			if (!data.dragState && data.ogH - diffY < data.minH) {
				data.offsetY = diffY - (diffY = data.ogH - data.minH);
			} else if (
				!data.dragState &&
				data.maxH &&
				data.ogH - diffY > data.maxH &&
				data.ogTop >= 0
			) {
				data.offsetY = diffY - (diffY = data.ogH - data.maxH);
			}

			data.calcMap & CALC_MASK.t && (data.ogTop += diffY);
			data.calcMap & CALC_MASK.h && (data.ogH -= data.dragState ? 0 : diffY);
		}
	},
	right: (diffX: number) => {
		if (data.resizeState & ELEMENT_MASK["resizable-r"].bit) {
			if (!data.dragState && data.ogW + diffX < data.minW) {
				data.offsetX = diffX - (diffX = data.minW - data.ogW);
			} else if (
				!data.dragState &&
				data.maxW &&
				data.ogW + diffX > data.maxW &&
				data.ogW + data.ogLeft < data.parent.width
			) {
				data.offsetX = diffX - (diffX = data.maxW - data.ogW);
			}

			data.calcMap & CALC_MASK.w && (data.ogW += data.dragState ? 0 : diffX);
		}
	},
	bottom: (diffY: number) => {
		if (data.resizeState & ELEMENT_MASK["resizable-b"].bit) {
			if (!data.dragState && data.ogH + diffY < data.minH) {
				data.offsetY = diffY - (diffY = data.minH - data.ogH);
			} else if (
				!data.dragState &&
				data.maxH &&
				data.ogH + diffY > data.maxH &&
				data.ogH + data.ogTop < data.parent.height
			) {
				data.offsetY = diffY - (diffY = data.maxH - data.ogH);
			}

			data.calcMap & CALC_MASK.h && (data.ogH += data.dragState ? 0 : diffY);
		}
	},
};

function handleDown(event: MouseEvent) {
	const targetEl = event.target as HTMLElement;
	const elDataName = targetEl.dataset.name;
	const elMaskVal = ELEMENT_MASK[elDataName as keyof typeof ELEMENT_MASK];

	if (
		resizableParentRef.value?.contains(targetEl) &&
		(targetEl.closest?.(`.${elDataName}`) ||
			targetEl.dataset.name?.includes(elDataName))
	) {
		elDataName === "drag-el" && (data.dragState = true);
		document.body.style.cursor = elMaskVal.cursor;
		// if (event.touches && event.touches.length >= 1) {
		// 	data.mouseX = event.touches[0].clientX;
		// 	data.mouseY = event.touches[0].clientY;
		// } else {
		event.preventDefault?.();
		data.mouseX = event.clientX;
		data.mouseY = event.clientY;
		// }
		data.offsetX = data.offsetY = 0;
		data.resizeState = elMaskVal.bit;

		if (!resizableParentRef.value?.parentElement) {
			console.error("No parent element", resizableParentRef.value);
		}

		data.parent.height =
			resizableParentRef.value?.parentElement?.clientHeight || 0;
		data.parent.width =
			resizableParentRef.value?.parentElement?.clientWidth || 0;
		const eventName = data.dragState ? "drag:start" : "resize:start";
		emitEvent(eventName);
	}
}
function handleUp() {
	if (data.resizeState !== 0) {
		data.resizeState = 0;
		document.body.style.cursor = "";
		const eventName = data.dragState ? "drag:end" : "resize:end";
		emitEvent(eventName);
		data.dragState = false;
	}
}
</script>

<template>
	<div
		ref="resizableParentRef"
		class="resizable-component"
		:style="style"
	>
		<slot></slot>
		<div
			v-for="(el, _index) in active"
			v-show="!maximize"
			:key="_index"
			class="resizable-handle"
			:data-name="'resizable-' + el"
			@mousedown="handleDown"
		></div>
	</div>
</template>

<style lang="scss" scoped>
.resizable-component {
	position: relative;

	& > .resizable-handle {
		display: block;
		position: absolute;
		touch-action: none;
		user-select: none;
	}

	& > [data-name="resizable-r"] {
		z-index: 90;
		cursor: e-resize;
		width: 12px;
		right: -6px;
		top: 0;
		height: 100%;
	}

	& > [data-name="resizable-rb"] {
		cursor: se-resize;
		width: 12px;
		height: 12px;
		right: -6px;
		bottom: -6px;
		z-index: 91;
	}

	& > [data-name="resizable-b"] {
		z-index: 90;
		cursor: s-resize;
		height: 12px;
		bottom: -6px;
		width: 100%;
		left: 0;
	}

	& > [data-name="resizable-lb"] {
		cursor: sw-resize;
		width: 12px;
		height: 12px;
		left: -6px;
		bottom: -6px;
		z-index: 91;
	}

	& > [data-name="resizable-l"] {
		z-index: 90;
		cursor: w-resize;
		width: 12px;
		left: -6px;
		height: 100%;
		top: 0;
	}

	& > [data-name="resizable-lt"] {
		cursor: nw-resize;
		width: 12px;
		height: 12px;
		left: -6px;
		top: -6px;
		z-index: 91;
	}

	& > [data-name="resizable-t"] {
		z-index: 90;
		cursor: n-resize;
		height: 12px;
		top: -6px;
		width: 100%;
		left: 0;
	}

	& > [data-name="resizable-rt"] {
		cursor: ne-resize;
		width: 12px;
		height: 12px;
		right: -6px;
		top: -6px;
		z-index: 91;
	}
}
</style>
