import { useToast, TYPE } from "vue-toastification";

import { defineStore } from "pinia";
import ToastComp from "@/components/shared/toast/ToastComp.vue";
import type { ToastComponent } from "vue-toastification/dist/types/types";

type TInfoOpt = Parameters<typeof toastInstance.info>[1];
type TSuccessOpt = Parameters<typeof toastInstance.success>[1];
type TWarningOpt = Parameters<typeof toastInstance.warning>[1];
type TErrorOpt = Parameters<typeof toastInstance.error>[1];

const toastInstance = useToast();
export const useToastStore = defineStore("toasts", () => {
	function openToastCore<
		T extends TInfoOpt | TSuccessOpt | TWarningOpt | TErrorOpt,
	>(msg: string, type: TYPE, opt: T) {
		const toastObj = {
			component: ToastComp,
			props: {
				type,
				msg,
				...opt,
			},
		} as ToastComponent;

		switch (type) {
			case "info": {
				toastInstance.info(toastObj, opt as TInfoOpt);
				break;
			}
			case "success": {
				toastInstance.success(toastObj, opt as TSuccessOpt);
				break;
			}
			case "warning": {
				toastInstance.warning(toastObj, opt as TWarningOpt);
				break;
			}
			case "error": {
				toastInstance.error(toastObj, opt as TErrorOpt);
				break;
			}
		}
	}

	function openGenToast(msg: string, type: keyof typeof TYPE, obj = {}) {
		// eslint-disable-next-line unicorn/prefer-switch
		if (type === "INFO") openToastInfo(msg, obj);
		else if (type === "SUCCESS") openToastSuccess(msg, obj);
		else if (type === "WARNING") openToastWarning(msg, obj);
		else if (type === "ERROR") openToastError(msg, obj);
		else openToastCore(msg, TYPE.DEFAULT, obj);
	}
	function openToastInfo(msg: string, opt: TInfoOpt = {}) {
		openToastCore<TInfoOpt>(msg, TYPE.INFO, opt);
	}
	function openToastSuccess(msg: string, opt: TSuccessOpt = {}) {
		openToastCore<TSuccessOpt>(msg, TYPE.SUCCESS, opt);
	}
	function openToastWarning(msg: string, opt: TWarningOpt = {}) {
		openToastCore<TWarningOpt>(msg, TYPE.WARNING, opt);
	}
	function openToastError(msg: string, opt: TErrorOpt = {}) {
		openToastCore<TErrorOpt>(msg, TYPE.ERROR, opt);
	}

	return {
		openGenToast,
		openToastInfo,
		openToastSuccess,
		openToastWarning,
		openToastError,
	};
});
