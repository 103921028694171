<script setup lang="ts">
// import api from "@/api";
import { useDefaultStore } from "@/store";
// import { DateInstance } from "@/assets/js/dateHelper";
import { type TStepItem } from "@/components/shared/wizard/WizardSteps.vue";

definePage({
	name: "HomePage",
});

export interface ILead {
	features: string;
	lds_design: string;
	lds_email: string;
	lds_id: number;
	lds_last_name: string;
	lds_name: string;
	lds_phone: string;
	lds_requested_date: string;
	lds_priority: string;
	lds_progress: string;
	lds_utc_created: string;
	lds_utc_deleted: string | null;
	lds_utc_updated: string | null;
	lds_comment: string;
	lds_ip: string;
	lds_country_code?: string | null;
	lds_city?: string | null;
	lds_referrer?: string | null;
	lds_platform?: string | null;
}

const stepsTest = ref<TStepItem[]>([
	{ label: "Najnovije", name: "true", checked: true },
	{ label: "Najstarije", name: "false", checked: false },
]);
const latestBool = ref<(typeof stepsTest.value)[0] | null>(stepsTest.value[0]);
const leads = ref<ILead[]>([]);
const store = useDefaultStore();

watch(
	() => latestBool.value,
	async (newValue) => {
		getLeads();
	},
);

async function getLeads() {
	const params = {
		latest: latestBool.value.name,
	};

	leads.value = await store.getLeads(params);
}

onMounted(async () => {
	await getLeads();
});
</script>

<template>
	<div class="landing-wrapper">
		<div class="landing-filters">
			<div class="dropdown-wrap">
				<div class="dropdown-label">Sortiraj po:</div>
				<multiselect-form
					v-model="latestBool"
					:options="stepsTest"
					label="label"
					value-prop="name"
				></multiselect-form>
			</div>
		</div>
		<div
			v-for="lead in leads"
			:key="lead.lds_id"
		>
			<lead-card-comp :lead="lead" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.landing-wrapper {
	padding: 24px;

	.landing-filters {
		height: 100px;
	}

	.filters-wrapper {
		.filter-item {
			&.first {
				margin-bottom: 24px;
			}
		}
	}
}

@media screen and (width > 768px) {
	.landing-wrapper {
		.filters-wrapper {
			display: flex;
			margin-right: 18px;

			.filter-item {
				margin-right: 24px;

				&.first {
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>
